import { template as template_bbf0848d531a4a499cefd9350a58555d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_bbf0848d531a4a499cefd9350a58555d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
