import { template as template_eda6e81cde1843bdb1db5d49fad72e9d } from "@ember/template-compiler";
const FKControlMenuContainer = template_eda6e81cde1843bdb1db5d49fad72e9d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
