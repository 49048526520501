import { template as template_a88fcdec32de4f0ca211b6b1cb9359ee } from "@ember/template-compiler";
const FKLabel = template_a88fcdec32de4f0ca211b6b1cb9359ee(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
